<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      autocomplete
      :autofocus="autofocus"
      class="text"
      itemvalue="OrdOrderID"
      :disabled="disabled"
      :value="value"
      id="OrdOrderID"
      itemtext="OrdOrderID"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
      @onClearClicked="onClearClicked()"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sPackingService from "@/services/FreshProduction/PackingService";
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    
    noDefault: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    onClear: {
      type: Boolean,
      default: false,
    },

    orderPID: {
      type: Number,
      default: 0,
    },
    customerID: {
      type: String,
      default: "",
    },
    areProduction: {
      type: String,
      default: "",
    },

  },
  watch: {
    text() {},
    value(value) {
      this.item = value;
    },
    customerID() {
      alert(this.customerID)
      if (this.customerID.length > 0) {
        this.config.params.UwoCustomerID = this.customerID;
      }
    },
    orderPID() {
      alert(this.orderPID)
      if (this.orderPID != 0) {
        this.config.params.UwoOrderPID = this.orderPID;
      }
    },
    areProduction() {
      alert(this.areProduction)
      if (this.areProduction.length > 0) {
        this.config.params.OrdAreProduction = this.areProduction;
      }
    },
  },
  methods: {
    onClearClicked() {
      this.$emit("onClearClicked");
    },
    input(val) {
      this.$emit("input", val);
    },
  },
  data: () => ({
    item: 0,
    val: "",
    config: {},
  }),

  created() {
    this.config = {
      url: _sPackingService.GetOrder(),
      title: this.label,
      params: {
        OrdAreProduction: "",
        TypeCultive: 0,
        UwoCustomerID: "",
        search: "",
        UwoOrderPID: 0,
        requestID: this.$fun.getUserID(),
      },
    };
  },
};
</script>
