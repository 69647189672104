<template>
  <v-container>
    <div class="col-md-12 col-sm-12">
      <v-card dense >
        <v-col
          cols="12"
          md="12"
          style="margin-left: 0px; padding: unset; margin-top: -12px;"
        >
          <s-toolbar :color="'#BAB6B5'" dark close @close="clickCancel()" />
        </v-col>

        <v-row style="margin: auto;">
          <v-col cols="12" lg="6" md="6">
            <s-text label="Producto" v-model="items.UwoMaterialListID" readonly>
            </s-text>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <s-text label="Cliente" v-model="items.UwoCustomerName" readonly>
            </s-text>
          </v-col>
          <v-col cols="12" lg="12" md="12">
            <s-text
              label="Descripcion Producto"
              v-model="items.UwoMaterialListName"
              readonly
            >
            </s-text>
          </v-col>

          <v-col cols="12" lg="4" md="4">
            <s-text
              label="Kilos Producto Terminado"
              v-model="items.PltGrossWeight"
              readonly
            >
            </s-text>
          </v-col>

          <v-col cols="12" lg="4" md="4">
            <s-text label="Pedido" v-model="items.OrdID" readonly> </s-text>
          </v-col>
          <v-col cols="12" lg="3" md="3">
            <s-text label="Norma Reparto" v-model="NormaReparto" readonly>
            </s-text>
          </v-col>

          <v-col cols="6" lg="4" md="4">
            <v-btn color="primary" @click="clickSAP()" :disabled="disabledBtn"
              >Cargar</v-btn
            >
            <span style="position: absolute; margin-left: 10px;"
              ><v-btn
                color="warning"
                @click="clickCancel()"
                :disabled="disabledBtn"
                >Cancelar</v-btn
              ></span
            >
          </v-col>
        </v-row>

        <v-row style="margin: auto;">
          <v-col cols="12" lg="12" md="12">
            <v-data-table
              item-key=""
              dense
              :items="itemsDetail"
              :headers="headers"
              :items-per-page="-1"
              hide-default-footer
            >
              <template v-slot:item.mPlannedQty="{ item, index }">
                
                <s-text
                  v-model="item.mPlannedQty"
                  decimal
                  :min="0"
                  
                  @input="inputKG(item, index)"
                  :readonly="index==valParamIndex ? false: true"
                />

              </template>  
            </v-data-table>
          </v-col>
        </v-row>


        <v-dialog v-model="processingSAP" :persistent="messageProcessingSAP.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessingSAP.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
					<v-card-text v-else>
					<div class="text--white pt-4">
						Error al realizar operacion en SAP <br />
						{{ messageProcessingSAP }}
					</div>
					</v-card-text>
				</v-card>
			</v-dialog>
      </v-card>
    </div>

    

  </v-container>
</template>

<script>
import _sPackingService from "../../../services/FreshProduction/PackingService";
import _sGenParamService from "../../../services/General/ParameterService";
export default {
  props: {
    items: {
      required: true,
      default: null,
    },
  },
  components: {},

  data: () => ({
    headers: [
      { text: "ItemCode", value: "mItemCode", width: 120 },
      { text: "ItemName", value: "mItemName", width: 350 },
      { text: "InventoryUOM", value: "mInventoryUOM", width: 150 },
      { text: "PlannedQty", value: "mPlannedQty", width: 150 },
      { text: "IssueMethod", value: "mIssueMethod", width: 150 },
    ],

    itemsAll: null,
    itemsDetail: [],
    disabledBtn: true,
    NormaReparto: null,
    valParamIndex: 0,
    processingSAP:false,
    errorSap: false,
    messageProcessingSAP: "",
  }),

  created () {
    this.loadParameter();
  },

  mounted() {
    this.processingSAP = true;
    _sPackingService
      .GetOrderFabrication(
        this.items.UwoOrderProductionID,
        this.$fun.getUserSAPInfo().UsrID,
        this.$fun.getUserID()
      )
      .then((r) => {
        if(r.status == 200){
          this.processingSAP = false;
          this.itemsAll = r.data;
          this.itemsDetail = this.itemsAll.itemsDetail;
          this.NormaReparto = this.itemsAll.NormaReparto;
          console.log(this.itemsAll);
        }
        
      }
      ,
							(e) => {
								this.errorSap = true;
								this.messageProcessingSAP = e.response.data.Message;
								this.processingSAP = true;
							}
      
      );
  },

  watch: {
    itemsDetail() {
      this.disabledBtn = false;
      
    },
  },
  methods: {

    loadParameter(){
        _sGenParamService
					.search(
						{ PrmName: "IndexDesbloquearParaModifcarKGEm" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.valParamIndex = resp.data.PrmValue;
              console.log(this.valParamIndex);
						}
					});
    },
    clickCancel() {
      this.$emit("clickCancel");
    },

    clickSAP() {

      console.log("itemsAll",this.itemsAll);
      
      this.items.CntPlaneadaStandar = this.items.PltWeightNet
      this.items.CntPlaneada = this.items.PltGrossWeight//this.itemsAll.CntPlaneada;
      this.items.itemsDetail = this.itemsAll.itemsDetail;

      console.log("Guardar",this.items);
      
      this.$fun
        .alert("Esta seguro de subir a SAP ? KG a Subir ==> " + this.items.CntPlaneada + ", OV ==> " + this.items.OrdID , "question")
        .then((val) => {
          
          if (val.value) {

            this.processingSAP = true;
             this.disabledBtn = true;
             
            _sPackingService
              .saveSAP(
                this.items,
                this.$fun.getUserSAPInfo().UsrID,
                this.$fun.getUserID()
              )
              .then((r) => {
                
                if(r.data == true){
                    this.processingSAP = false;
                    this.$fun.alert("Registro subido correctamente", "success");
                    this.disabledBtn = false;
                    this.clickCancel();
                }
                console.log("r.data", r.data);
              },
							(e) => {
								this.errorSap = true;
								this.messageProcessingSAP = e.response.data.Message;
								this.processingSAP = true;
							}
              
              
            );
          }
        });
    },

    inputKG(item, index){
      if(index == this.valParamIndex){

        this.items.PltGrossWeight = item.mPlannedQty
        console.log("probandooooo", item, index);
      } 
        
    }
  },
};
</script>
