<template>
	<!-- <v-container> -->

	<!-- <div class="col-md-12 col-sm-12"> -->
	<v-card dense>
		<s-select-definition :def="1181" @change="change($event)" hidden />
		<v-row style="margin: auto">
			<v-col cols="12">
				<b>Para seleccionar una opcion, Pulse el boton: </b>
			</v-col>
			<v-col cols="12" lg="6" md="6" v-for="col in items" :key="col.DedID">
				<v-card>
					<v-btn
						style="
							border-radius: 100%;
							height: 120px;
							width: 120px;
							margin: auto;
							display: flex;
							justify-content: space-evenly;"
						color="warning"
						@click="click(col)"
					>
						<span class="white--text text-h4">{{
							col.DedDescription
						}}</span>
					</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
	<!-- </div> -->
	<!-- </v-container> -->
</template>

<script>
	export default {
		components: {},

		data() {
			return {
				radios: null,
				items: [],
			};
		},

		create() {},
		methods: {
			change(val) {
				this.items = val;
			},
			 
			click(val) {
				this.$emit("click", val);
			},
		},
	};
</script>
